// localStorage keys
export const USER_KEY = 'monitoring-user';

export const APP_THEME = {
  token: {
    colorPrimary: '#444de7',
  },
};

export const DEFAULT_MEMORY_USAGE_LIMIT = 70;
