import type { MenuProps } from 'antd';

export const getCronWidgetActions = (action: () => void, isCronActive?: boolean): MenuProps['items'] => {
  const actions = [
    {
      key: '1',
      label: `${isCronActive ? 'Stop cron job' : 'Start cron job'}`,
      onClick: () => {
        action();
      },
    },
  ];

  return actions;
};
