import { apiSlice } from 'api/apiSlice';
import { AUTH_LOGIN_PATH } from 'features/auth/constants/constants';

interface ILogin {
  username: string;
  password: string;
}

export const authApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    login: builder.mutation<Record<'access_token', string>, ILogin>({
      query: (credentials) => ({
        url: AUTH_LOGIN_PATH,
        method: 'POST',
        body: { ...credentials },
      }),
    }),
  }),
});

export const { useLoginMutation } = authApiSlice;
