import { FC, useCallback, useMemo, useState } from 'react';
import { CloudDownloadOutlined, DeleteOutlined, EditOutlined, EllipsisOutlined, PlusOutlined } from '@ant-design/icons';
import { App, Button, Dropdown, MenuProps, Space } from 'antd';
import { MonitoringResult, MonitoringResultDocument } from 'features/interfaces/MonitoringResult';
import { isTargetDocument, Target, TargetDocument } from 'features/interfaces/Target';
import { useMonitoreTargetMutation, useMonitoreTestDataMutation } from 'features/monitoringJobs/monitoringJobsApiSlice';
import {
  useActivateTargetMutation,
  useCreatePostMutation,
  useDeletePostMutation,
  useGetTargetsQuery,
  useUpdatePostMutation,
} from 'features/targets/targetsApiSlice';
import { useCheckCronJobQuery, useStartCronJobMutation, useStopCronJobMutation } from 'features/tasks/tasksApiSlice';
import TargetDrawer from 'pages/targets/addTargetDrawer/AddTargetDrawer';
import { TARGETS_TABLE_COLUMNS } from 'pages/targets/constants/table';
import Header from 'shared/components/header/Header';
import NoResult from 'shared/components/noResult/NoResult';
import Table from 'shared/components/table/Table';
import Widget from 'shared/components/Widget/Widget';
import { getCronWidgetActions } from 'shared/helpers/actionsHelpers.ts';
import { exportJson } from 'shared/helpers/jsonHelpers';
import { getQueryErrorMessage } from 'shared/helpers/queryErrorMessageHelper';
import { Nullable } from 'shared/types/generics';

import './Targets.scss';

const Targets: FC = () => {
  const { notification, modal } = App.useApp();
  const { data: targets, isLoading, isSuccess, isError, error } = useGetTargetsQuery('');
  const [createTarget, { isLoading: isCreateTargetLoading }] = useCreatePostMutation();
  const [editTarget, { isLoading: isEditTargetLoading }] = useUpdatePostMutation();
  const [deleteTarget, { isLoading: isDeleteTargetLoading }] = useDeletePostMutation();
  const [changeTargetMonitoringStatus, { isLoading: isChangeStatusLoading }] = useActivateTargetMutation();
  const [monitoreTarget, { isLoading: isMonitoreTargetLoading }] = useMonitoreTargetMutation();
  const [monitoreTestData, { isLoading: isMonitoreTestDataLoading }] = useMonitoreTestDataMutation();

  const { data: isCronActive, isLoading: isCheckCronJobLoading, isError: isCheckCronJobError } = useCheckCronJobQuery();
  const [startCronJob] = useStartCronJobMutation();
  const [stopCronJob] = useStopCronJobMutation();

  const [isDrawerOpen, setIsDrawerOpen] = useState<boolean>(false);
  const [selectedTargetId, setSelectedTargetId] = useState<string>('');
  const [monitoringResult, setMonitoringResult] = useState<Nullable<MonitoringResult | MonitoringResultDocument>>(null);

  const onClose = (): void => {
    setIsDrawerOpen(false);
    setSelectedTargetId('');
    setMonitoringResult(null);
  };

  const handleOpenDrawer = useCallback(() => {
    setIsDrawerOpen(true);
  }, []);

  const handleChangeisCronActive = useCallback(async (): Promise<void> => {
    let result;

    if (isCronActive) {
      result = await stopCronJob();
    } else {
      result = await startCronJob();
    }

    if ('error' in result) {
      notification.error({
        message: 'An error occurred while changing the cron status!',
        placement: 'bottomRight',
      });
    } else {
      notification.success({
        message: 'The cron status has successfully been changed!',
        placement: 'bottomRight',
      });
    }
  }, [isCronActive, stopCronJob, startCronJob, notification]);

  const getSelectedTarget = useCallback((): Nullable<TargetDocument> => {
    return targets?.find((target) => target._id === selectedTargetId);
  }, [selectedTargetId, targets]);

  const handleAddTarget = useCallback(
    async (formData: Target) => {
      let result;
      const updatedTarget: Target | TargetDocument = {
        ...getSelectedTarget(),
        ...formData,
      };

      if (isTargetDocument(updatedTarget)) {
        result = await editTarget(updatedTarget);
      } else {
        result = await createTarget(updatedTarget);
      }
      if ('error' in result) {
        notification.error({
          message: `Error to ${selectedTargetId ? 'update' : 'create'} a target`,
          description: getQueryErrorMessage(result.error),
          placement: 'bottomRight',
        });
      } else {
        notification.success({
          message: `Target ${selectedTargetId ? 'updated' : 'created'}`,
          placement: 'bottomRight',
        });
        onClose();
      }
    },
    [createTarget, editTarget, getSelectedTarget, notification, selectedTargetId],
  );

  const handleMonitoreData = useCallback(
    async (data: Target | TargetDocument) => {
      let result;
      if (isTargetDocument(data)) {
        result = await monitoreTarget(data);
      } else {
        result = await monitoreTestData(data);
      }
      if ('error' in result) {
        notification.error({
          message: 'Error while monitoring',
          description: getQueryErrorMessage(result.error),
          placement: 'bottomRight',
        });
      } else {
        setMonitoringResult(result.data);
      }
    },
    [monitoreTarget, monitoreTestData, notification],
  );

  const handleTestTarget = useCallback(
    async (target: TargetDocument) => {
      setSelectedTargetId(target._id);
      handleOpenDrawer();
      await handleMonitoreData(target);
    },
    [handleMonitoreData, handleOpenDrawer],
  );

  const handleDeleteTarget = useCallback(
    async (target: TargetDocument) => {
      modal.confirm({
        title: 'Delete target',
        content: `Are you sure you want to delete ${target.name}`,
        onOk: async () => {
          const result = await deleteTarget(target);
          if ('error' in result) {
            notification.error({
              message: 'Target not deleted',
              description: getQueryErrorMessage(result.error),
              placement: 'bottomRight',
            });
          } else {
            notification.success({
              message: 'Target successfully deleted',
              placement: 'bottomRight',
            });
          }
        },
      });
    },
    [deleteTarget, modal, notification],
  );

  const handleChangeTargetStatus = useCallback(
    async (target: TargetDocument) => {
      modal.confirm({
        title: 'Monitoring status',
        content: `Are you sure you want to ${target.isActive ? 'stop monitoring' : 'start monitoring'} ${target.name}`,
        onOk: async () => {
          const result = await changeTargetMonitoringStatus(target);
          if ('error' in result) {
            notification.error({
              message: 'Request failed',
              description: getQueryErrorMessage(result.error),
              placement: 'bottomRight',
            });
          } else {
            notification.success({
              message: result.data,
              placement: 'bottomRight',
            });
          }
        },
      });
    },
    [changeTargetMonitoringStatus, modal, notification],
  );

  const handleExport = useCallback(() => {
    exportJson(targets, 'targets');
  }, [targets]);

  const handleEditTarget = useCallback(
    (target: TargetDocument): void => {
      setSelectedTargetId(target._id);
      handleOpenDrawer();
    },
    [handleOpenDrawer],
  );

  const getTargetAdditionalActions = useCallback(
    (target: TargetDocument) => {
      const actions: MenuProps['items'] = [
        {
          label: <p>Monitore target</p>,
          key: '0',
          onClick: () => {
            void handleTestTarget(target);
          },
        },
        {
          label: <p>{target.isActive ? 'Stop' : 'Start'} monitoring</p>,
          key: '1',
          onClick: () => {
            void handleChangeTargetStatus(target);
          },
        },
      ];
      return { items: actions };
    },
    [handleChangeTargetStatus, handleTestTarget],
  );

  const COLUMNS = useMemo(() => {
    return [
      ...TARGETS_TABLE_COLUMNS,
      {
        title: 'Actions',
        key: 'actions',
        render: (_: null, record: TargetDocument) => {
          return (
            <Space size="middle">
              <Button
                onClick={() => {
                  handleEditTarget(record);
                }}
                icon={<EditOutlined />}
              />
              <Button
                onClick={() => {
                  void handleDeleteTarget(record);
                }}
                icon={<DeleteOutlined />}
              />
              <Dropdown menu={getTargetAdditionalActions(record)} trigger={['click']} placement="topLeft">
                <Button icon={<EllipsisOutlined rotate={90} />} />
              </Dropdown>
            </Space>
          );
        },
      },
    ];
  }, [getTargetAdditionalActions, handleDeleteTarget, handleEditTarget]);

  const content = (
    <section className="target-page">
      <Header border={false} title="Targets">
        <Button onClick={handleExport} disabled={!targets?.length} icon={<CloudDownloadOutlined />}>
          Export
        </Button>
        <Button type="primary" onClick={handleOpenDrawer} icon={<PlusOutlined />}>
          Add target
        </Button>
      </Header>

      <section className="widget flex gap-5 mx-auto w-full sm:px-6 lg:px-8 my-5">
        <Widget title="Total monitoring targets" content={targets?.length} loading={isLoading || isDeleteTargetLoading} isError={isError} />
        <Widget
          title="Cron status"
          content={isCronActive ? 'Activated' : 'Stopped'}
          actions={getCronWidgetActions(handleChangeisCronActive, isCronActive)}
          loading={isCheckCronJobLoading}
          isError={isCheckCronJobError}
          color={isCronActive ? 'green' : 'red'}
        />
      </section>

      {!isError ? (
        <Table className="my-6" columns={COLUMNS} data={targets} loading={isLoading || isDeleteTargetLoading || isChangeStatusLoading} />
      ) : (
        <NoResult />
      )}

      <TargetDrawer
        target={getSelectedTarget()}
        loading={isCreateTargetLoading || isEditTargetLoading || isMonitoreTestDataLoading || isMonitoreTargetLoading}
        open={isDrawerOpen}
        onClose={onClose}
        onSubmit={handleAddTarget}
        onTest={handleMonitoreData}
        jsonData={monitoringResult}
      />
    </section>
  );

  return content;
};

export default Targets;
