import React from 'react';
import { Tag } from 'antd';
import type { ColumnsType } from 'antd/es/table';
import { TargetDocument } from 'features/interfaces/Target';

export const TARGETS_TABLE_COLUMNS: ColumnsType<TargetDocument> = [
  {
    title: 'Name',
    dataIndex: 'name',
    key: 'name',
    render: (text, record: TargetDocument) => {
      if (!record.isActive) {
        return (
          <p>
            {text} <span className="text-red-600">(disabled)</span>
          </p>
        );
      }
      return <p>{text}</p>;
    },
  },
  {
    title: 'URL',
    dataIndex: 'url',
    key: 'url',
    render: (link) => <a href={link}>{link}</a>,
  },
  {
    title: 'Monitoring types',
    key: 'types',
    dataIndex: 'types',
    render: (_, { types }) => (
      <>
        {types.map((type) => {
          return (
            <Tag color="geekblue" key={type}>
              {type.toUpperCase()}
            </Tag>
          );
        })}
      </>
    ),
  },
  {
    title: 'SSH',
    dataIndex: 'ip',
    key: 'ip',
  },
];
