import React, { FC } from 'react';
import { Table } from 'antd';
import type { ColumnsType } from 'antd/es/table';
import classNames from 'classnames';
import { AnyObject, CustomAny } from 'shared/types/generics';

interface TableProps {
  data?: AnyObject[];
  columns: ColumnsType<CustomAny>;
  loading?: boolean;
  bordered?: boolean;
  className?: string;
}

const CustomTable: FC<TableProps> = (props) => {
  const { columns, data, loading, className, bordered = true } = props;

  return (
    <div className={classNames('table-component w-full sm:px-6 lg:px-8', className)}>
      <Table
        className={classNames(bordered ? 'border-solid border border-gray-200 rounded-lg' : '')}
        columns={columns}
        dataSource={data}
        rowKey={(record) => record._id}
        loading={loading}
      />
    </div>
  );
};

export default CustomTable;
