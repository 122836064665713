import { apiSlice } from 'api/apiSlice';
import { MonitoringResult, MonitoringResultDocument } from 'features/interfaces/MonitoringResult';
import { Target, TargetDocument } from 'features/interfaces/Target';

import { MONITORING_JOBS_BASE_PATH } from './constants/constants';

export const monitoringJobsApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    monitoreTestData: builder.mutation<MonitoringResult, Target>({
      query: (target) => ({
        url: MONITORING_JOBS_BASE_PATH,
        method: 'POST',
        body: target,
      }),
    }),
    monitoreTarget: builder.mutation<MonitoringResultDocument, TargetDocument>({
      query: (target) => ({
        url: `${MONITORING_JOBS_BASE_PATH}/${target._id}`,
        method: 'POST',
      }),
    }),
  }),
});

export const { useMonitoreTargetMutation, useMonitoreTestDataMutation } = monitoringJobsApiSlice;
