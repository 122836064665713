import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { AnyObject } from 'shared/types/generics';

const apiHost = process.env.REACT_APP_API_HOST || 'http://localhost:3000/api';

const baseQuery = fetchBaseQuery({
  baseUrl: apiHost,
  mode: 'cors',
  prepareHeaders: (headers, { getState }: AnyObject) => {
    const token: string = getState().auth.token;

    if (token) {
      headers.set('authorization', `Bearer ${token}`);
    }

    return headers;
  },
});

export const apiSlice = createApi({
  baseQuery,
  tagTypes: ['Targets', 'Tasks'],
  endpoints: (builder) => ({}),
});
