import jwt_decode from 'jwt-decode';
import { USER_KEY } from 'shared/constants/application';
import { AnyObject } from 'shared/types/generics';

export const setAuthToken = (data: string, remember: boolean): void => {
  remember ? localStorage.setItem(USER_KEY, data) : sessionStorage.setItem(USER_KEY, data);
};

export const updateAuthToken = (data: string): void => {
  localStorage.getItem(USER_KEY) ? localStorage.setItem(USER_KEY, data) : sessionStorage.setItem(USER_KEY, data);
};

export const clearAuthToken = (): void => {
  sessionStorage.removeItem(USER_KEY);
  localStorage.removeItem(USER_KEY);
};

export const getAuthToken = (): string | null => {
  return localStorage.getItem(USER_KEY) || sessionStorage.getItem(USER_KEY);
};

export const getUsernameFromToken = (): string | null => {
  return !isAuthKeyEmpty() ? jwt_decode<AnyObject>(getAuthToken() as string).username : null;
};

export const isAuthKeyEmpty = (): boolean => !getAuthToken();
