import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { UserState } from 'features/interfaces/UserState';
import { clearAuthToken, getAuthToken, getUsernameFromToken } from 'shared/helpers/accessTokenHelper';
import { Nullable } from 'shared/types/generics';
import { RootState } from 'store/store';

const initialState: UserState = {
  user: getUsernameFromToken(),
  token: getAuthToken(),
};

const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    setCredentials: (state, action: PayloadAction<{ username: string; token: string }>) => {
      const { username, token } = action.payload;
      state.user = username;
      state.token = token;
    },
    logout: (state) => {
      state.user = null;
      state.token = null;
      clearAuthToken();
    },
  },
});

export const { setCredentials, logout } = authSlice.actions;

export default authSlice.reducer;

export const selectCurrentUser = (state: RootState): Nullable<string> => state.auth.user;
export const selectCurrentToken = (state: RootState): Nullable<string> => state.auth.token;
