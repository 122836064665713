import { FC } from 'react';
import { Route, Routes } from 'react-router-dom';
import { StyleProvider } from '@ant-design/cssinjs';
import { App, ConfigProvider } from 'antd';
import RequireAuth from 'features/auth/requireAuth';
import Login from 'pages/login/Login';
import NotFoundPage from 'pages/notFound/NotFoundPage';
import Targets from 'pages/targets/Targets';
import Layout from 'shared/components/Layout';
import { APP_THEME } from 'shared/constants/application';

const MyApp: FC = () => {
  return (
    <ConfigProvider theme={APP_THEME}>
      {/* we shoud use StyleProvider to void conflicts with tailwind css styles */}
      <StyleProvider hashPriority="high">
        <App>
          <Routes>
            <Route path="/" element={<Layout />}>
              {/* public routes */}
              <Route path="login" element={<Login />} />

              {/* protected routes */}
              <Route element={<RequireAuth />}>
                <Route path="/" element={<Targets />} />
              </Route>
            </Route>
            <Route path="*" element={<NotFoundPage />} />
          </Routes>
        </App>
      </StyleProvider>
    </ConfigProvider>
  );
};

export default MyApp;
