export const commonWidgetStyles = {
  header: {
    borderBottom: 'none',
    padding: '15px 10px 0px 25px',
    justifyContent: 'center',
  },

  body: {
    padding: '0px 15px 15px 25px',
  },

  size: {
    width: 300,
  },
};
