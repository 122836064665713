import { MonitoringType } from 'shared/enums/MonitoringType';

export interface TargetDocument extends Target {
  _id: string;
  createdAt: string;
  updatedAt: string;
}

export interface Target {
  name: string;
  url: string;
  isActive: boolean;
  types: MonitoringType[];
  ip?: string;
  memoryUsageLimit?: number;
}

export const isTargetDocument = (target: Target | TargetDocument): target is TargetDocument => {
  return '_id' in target;
};
