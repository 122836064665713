import { FC } from 'react';
import { MoreOutlined } from '@ant-design/icons';
import { Button, Card, Dropdown, Typography } from 'antd';
import { ItemType } from 'antd/es/menu/hooks/useItems';
import { commonWidgetStyles } from 'shared/components/Widget/constants';

interface WidgetProps {
  title: string;
  content: React.ReactNode;
  loading?: boolean;
  actions?: ItemType[];
  color?: string;
  isError?: boolean;
}

const Widget: FC<WidgetProps> = (props) => {
  const { title, content, actions, color, loading, isError } = props;

  const actionButton = actions ? (
    <Dropdown menu={{ items: actions }} placement="topLeft" trigger={['click']}>
      <Button icon={<MoreOutlined />}></Button>
    </Dropdown>
  ) : null;

  return (
    <>
      <Card
        extra={!loading && !isError && actionButton}
        headStyle={commonWidgetStyles.header}
        bodyStyle={commonWidgetStyles.body}
        title={title}
        style={commonWidgetStyles.size}
        bordered
        loading={loading}
      >
        <div>
          <Typography style={{ color, fontSize: '34px' }} className="font-light mt-2">
            {!isError ? content : 'No result'}
          </Typography>
        </div>
      </Card>
    </>
  );
};

export default Widget;
