import { FC } from 'react';
import { Link } from 'react-router-dom';
import { Button, Result } from 'antd';

const NotFoundPage: FC = () => (
  <div className="p-[5%]">
    <Result
      status="404"
      title="404"
      subTitle="Sorry, the page you visited does not exist."
      extra={
        <Link to="/">
          <Button type="primary">Back Home</Button>
        </Link>
      }
    />
  </div>
);

export default NotFoundPage;
