import { CustomAny } from 'shared/types/generics';

export const exportJson = (data: CustomAny, fileName: string): void => {
  const jsonString = `data:text/json;chatset=utf-8,${encodeURIComponent(JSON.stringify(data))}`;
  const link = document.createElement('a');
  link.href = jsonString;
  link.download = `${fileName}.json`;

  link.click();
};
