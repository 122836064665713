import { apiSlice } from 'api/apiSlice';
import { Target, TargetDocument } from 'features/interfaces/Target';
import { TARGETS_BASE_PATH } from 'features/targets/constants/constants';

export const targetsApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getTargets: builder.query<TargetDocument[], string>({
      query: () => TARGETS_BASE_PATH,
      keepUnusedDataFor: 10,
      providesTags: (result) => ['Targets'],
    }),
    createPost: builder.mutation<TargetDocument, Target>({
      query: (target) => ({
        url: TARGETS_BASE_PATH,
        method: 'POST',
        body: target,
      }),
      invalidatesTags: ['Targets'],
    }),
    updatePost: builder.mutation<TargetDocument, TargetDocument>({
      query: (target) => ({
        url: `${TARGETS_BASE_PATH}/${target._id}`,
        method: 'PUT',
        body: target,
      }),
      invalidatesTags: ['Targets'],
    }),
    deletePost: builder.mutation<TargetDocument, TargetDocument>({
      query: (target) => ({
        url: `${TARGETS_BASE_PATH}/${target._id}`,
        method: 'DELETE',
      }),
      invalidatesTags: ['Targets'],
    }),
    activateTarget: builder.mutation<string, TargetDocument>({
      query: (target) => ({
        url: `${TARGETS_BASE_PATH}/${target._id}/activate`,
        method: 'POST',
      }),
      invalidatesTags: ['Targets'],
    }),
  }),
});

export const { useGetTargetsQuery, useCreatePostMutation, useUpdatePostMutation, useDeletePostMutation, useActivateTargetMutation } =
  targetsApiSlice;
