import React, { FC, ReactNode } from 'react';
import classNames from 'classnames';

import './Header.scss';

interface HeaderProps {
  title: ReactNode;
  dark?: boolean;
  border?: boolean;
  children?: ReactNode;
  className?: string;
}

const Header: FC<HeaderProps> = (props) => {
  const { className, title, dark = false, border = true } = props;

  return (
    <div
      className={classNames(
        'mx-auto w-full sm:px-6 lg:px-8',
        dark ? 'header-dark__color text-white' : 'bg-white text-gray-800',
        border ? 'border-solid border-b border-gray-200' : '',
        className,
      )}
    >
      <div className="relative flex h-16 items-center justify-between">
        <div className="flex flex-1 items-center justify-center sm:items-stretch sm:justify-start">
          <div className="flex items-center flex-shrink-0 text-2xl">{title}</div>
          <div className="w-full flex justify-end items-center space-x-4">{props.children}</div>
        </div>
      </div>
    </div>
  );
};

export default Header;
