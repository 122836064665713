import { FC } from 'react';
import { useSelector } from 'react-redux';
import { Navigate, Outlet, useLocation } from 'react-router-dom';
import { DeploymentUnitOutlined } from '@ant-design/icons';
import { AnyAction } from '@reduxjs/toolkit';
import { Button } from 'antd';
import { logout, selectCurrentToken, selectCurrentUser } from 'features/auth/authSlice';
import AppHeader from 'shared/components/header/Header';
import { useAppDispatch } from 'shared/hooks/hooks';

const RequireAuth: FC = () => {
  const dispatch = useAppDispatch();
  const token = useSelector(selectCurrentToken);
  const username = useSelector(selectCurrentUser);
  const location = useLocation();

  const handleLogout = (): AnyAction => dispatch(logout());

  return token ? (
    <>
      <AppHeader
        dark
        title={
          <>
            <DeploymentUnitOutlined className="pr-4" />
            <h1>Monitoring service</h1>
          </>
        }
      >
        <div>{username}</div>
        <Button ghost onClick={handleLogout}>
          Logout
        </Button>
      </AppHeader>
      <Outlet />
    </>
  ) : (
    <Navigate to="/login" state={{ from: location }} replace />
  );
};
export default RequireAuth;
