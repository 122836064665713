import { apiSlice } from 'api/apiSlice';
import { TASKS_BASE_PATH } from 'features/tasks/constants/constants';
import { CustomVoid } from 'shared/types/generics';

export const tasksApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    checkCronJob: builder.query<boolean, CustomVoid>({
      query: () => `${TASKS_BASE_PATH}/isAlive`,
      providesTags: (result) => ['Tasks'],
    }),
    startCronJob: builder.mutation<string, CustomVoid>({
      query: () => ({
        url: `${TASKS_BASE_PATH}/start`,
        method: 'POST',
      }),
      invalidatesTags: ['Tasks'],
    }),
    stopCronJob: builder.mutation<string, CustomVoid>({
      query: () => ({
        url: `${TASKS_BASE_PATH}/stop`,
        method: 'POST',
      }),
      invalidatesTags: ['Tasks'],
    }),
  }),
});

export const { useCheckCronJobQuery, useStartCronJobMutation, useStopCronJobMutation } = tasksApiSlice;
