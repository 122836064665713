import { SelectProps } from 'antd';
import { MonitoringType } from 'shared/enums/MonitoringType';

export const MonitoringTypesOptions: SelectProps['options'] = [
  {
    label: 'ping',
    value: MonitoringType.ping,
  },
  {
    label: 'http status',
    value: MonitoringType.http,
  },
  {
    label: 'server resources',
    value: MonitoringType.resources,
  },
  {
    label: 'mongodb connection',
    value: MonitoringType.mongodb,
  },
  {
    label: 'sqldb connection',
    value: MonitoringType.sqldb,
  },
];
