import React, { FC, useCallback, useEffect, useState } from 'react';
import ReactJson from 'react-json-view';
import { Button, Col, Drawer, Form, Input, InputNumber, Row, Select, Space } from 'antd';
import { Target, TargetDocument } from 'features/interfaces/Target';
import { MonitoringTypesOptions } from 'pages/targets/constants/constants';
import { DEFAULT_MEMORY_USAGE_LIMIT } from 'shared/constants/application';
import { MonitoringType } from 'shared/enums/MonitoringType';
import { AnyObject, Nullable } from 'shared/types/generics';

interface AddTargetDrawerProps {
  open: boolean;
  loading?: boolean;
  target?: Nullable<TargetDocument>;
  jsonData?: Nullable<AnyObject>;
  onClose: () => void;
  onSubmit: (data: Target) => void;
  onTest: (data: Target) => void;
}

const AddTargetDrawer: FC<AddTargetDrawerProps> = (props) => {
  const { open, onClose, onSubmit, onTest, target, jsonData, loading = false } = props;

  const [form] = Form.useForm();
  const [isFormValid, setIsFormValid] = useState<boolean>(true);

  useEffect(() => {
    if (open) {
      form.resetFields();
      setIsFormValid(true);
    }
  }, [form, open]);

  const onFinish = useCallback(
    (values: Target) => {
      onSubmit(values);
    },
    [onSubmit],
  );

  const onFieldsChange = useCallback(() => {
    setIsFormValid(!form.getFieldsError().filter((field) => field.errors.length).length);
  }, [form]);

  const handleTestClick = useCallback(async () => {
    await form.validateFields();
    onTest(form.getFieldsValue());
  }, [form, onTest]);

  const onReset = useCallback(() => {
    form.resetFields();
    onClose();
  }, [form, onClose]);

  return (
    <Drawer
      title={target?._id ? `Edit ${target.name}` : 'Add new monitoring target'}
      width={720}
      onClose={onClose}
      open={open}
      bodyStyle={{ paddingBottom: 80 }}
      extra={
        <Space>
          <Button disabled={loading} onClick={onReset}>
            Cancel
          </Button>
          <Button disabled={loading || !isFormValid} onClick={handleTestClick}>
            Test
          </Button>
          <Button disabled={!isFormValid} onClick={form.submit} loading={loading} type="primary">
            Submit
          </Button>
        </Space>
      }
    >
      <Form
        initialValues={{
          name: target?.name,
          url: target?.url,
          types: target?.types,
          ip: target?.ip,
          memoryUsageLimit: target?.memoryUsageLimit ?? DEFAULT_MEMORY_USAGE_LIMIT,
        }}
        disabled={loading}
        form={form}
        name="target-form"
        layout="vertical"
        onFinish={onFinish}
        onFieldsChange={onFieldsChange}
        requiredMark={false}
      >
        <Row gutter={16}>
          <Col span={12}>
            <Form.Item name="name" label="Name" rules={[{ required: true, message: 'Please enter user name' }]}>
              <Input placeholder="Please enter target name" />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={16}>
          <Col span={24}>
            <Form.Item name="url" label="Target url" rules={[{ required: true, message: 'Please enter url' }]}>
              <Input placeholder="Please enter url" />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={16}>
          <Col span={24}>
            <Form.Item name="types" label="Monitoring types" rules={[{ required: true, message: 'Please select monitoring types' }]}>
              <Select mode="multiple" allowClear style={{ width: '100%' }} placeholder="Please select" options={MonitoringTypesOptions} />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={16}>
          <Col span={12}>
            <Form.Item noStyle shouldUpdate={(prevValues, currentValues) => prevValues.types !== currentValues.types}>
              {({ getFieldValue }) =>
                getFieldValue('types')?.includes(MonitoringType.resources) ? (
                  <Form.Item
                    name="ip"
                    label="SSH connection string"
                    rules={[
                      {
                        required: true,
                        message: 'Please enter ssh connection string',
                      },
                    ]}
                  >
                    <Input placeholder="Please target ip" />
                  </Form.Item>
                ) : null
              }
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item noStyle shouldUpdate={(prevValues, currentValues) => prevValues.types !== currentValues.types}>
              {({ getFieldValue }) =>
                getFieldValue('types')?.includes(MonitoringType.resources) ? (
                  <Form.Item
                    name="memoryUsageLimit"
                    label="Memory usage limit"
                    rules={[
                      {
                        required: true,
                        message: 'Please enter memory usage limit',
                      },
                      {
                        type: 'number',
                        min: 0,
                        max: 100,
                        message: 'Value should be between 0 and 100',
                      },
                    ]}
                  >
                    <InputNumber />
                  </Form.Item>
                ) : null
              }
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={16}>
          <Col span={24}>
            <Form.Item
              label="Description"
              rules={[
                {
                  message: 'please enter url description',
                },
              ]}
            >
              <Input.TextArea rows={4} placeholder="you can enter target description" />
            </Form.Item>
          </Col>
        </Row>
      </Form>
      {jsonData ? <ReactJson src={jsonData} collapsed={2} enableClipboard={false} displayDataTypes={false} quotesOnKeys={false} /> : null}
    </Drawer>
  );
};

export default AddTargetDrawer;
